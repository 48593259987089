import React from 'react'
import renderBlok from '@renderBlok'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import H3 from '@system/h3'
import H4 from '@system/h4'
import H5 from '@system/h5'
import H6 from '@system/h6'
import MarkdownHandler from '@system/markdown'
import classNames from 'classnames'
import get from 'lodash/get'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'
import font from '@helpers/font'
import intelligentColumns from '@helpers/intelligent-columns'
import Icon from '@system/icon'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  background: {
    background: (props) => props.backgroundColor?.color || 'transparent',
  },
  textContainer: {
    textAlign: 'center',
  },
  eyebrow: {
    textTransform: 'uppercase',
    color: theme.palette.text.tertiary,
    textAlign: 'center',
    padding: '1rem 0',
  },
  headline: {
    fontWeight: (props) =>
      !!props.boldTitle && theme.typography.fontWeightMedium,
    color: (props) => props.color?.color || theme.palette.secondary.main,
    paddingTop: (props) =>
      props.variants === 'header-with-bg-image'
        ? '51px !important'
        : '20px !important',
    padding: '0 0 2rem',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      padding: ({ removeMargin }) => (removeMargin ? '0px' : '0px 40px 2rem'),
    },
    textAlign: 'center',
    margin: '0',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.tertiary,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: 0,
    '&:before': {
      content: '""',
      height: '1px',
      flex: 1,
      backgroundColor: theme.palette.background.slate,
      marginRight: '1rem',
    },
    '&:after': {
      content: '""',
      height: '1px',
      flex: 1,
      backgroundColor: theme.palette.background.slate,
      marginLeft: '1rem',
    },
  },
  lightBlueTitle: {
    color: theme.palette.secondary.main,
    ...makeResponsiveFontSizes(56),
  },
  boldTitle: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightExtraBold,
    ...makeResponsiveFontSizes(56),
  },
  containerClass: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  displayCards: (props) => ({
    display: props.isNav ? 'table-cell' : 'flex',
    flexDirection: 'column',
    alignItems: props.isNav ? 'flex-start' : 'center',
    margin: '0 auto',
    padding: props.isNav ? '.5rem 24px 0 0' : '.5rem',
    ...intelligentColumns(props.cards.length, props.overrideColumnWidth),
  }),
  displayCardsBlackBackground: {
    '&:not(:last-of-type) > div:after': {
      backgroundColor: theme.palette.common.white,
      content: '""',
      height: '1px',
      position: 'absolute',
      bottom: '-3.5rem',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        bottom: 'unset',
        height: '100%',
        right: '-1.5rem',
        width: '1px',
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '1.5rem !important',
      paddingRight: '1.5rem !important',
    },
  },
  descriptionContainer: {
    margin: (props) =>
      props.variants === 'header-with-bg-image'
        ? '16px 15% 24px'
        : '1rem 0 3rem',
  },
  description: {
    textAlign: 'center',
    color: (props) => props.color?.color || theme.palette.text.tertiary,
    display: 'inline',
    [theme.breakpoints.up('sm')]: {
      margin: '15px 0px',
    },
    fontWeight: (props) =>
      props.variants === 'black-background'
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightRegular,
    width: (props) =>
      props.variants === 'header-with-bg-image' ? '90%' : '100%',
    ...makeResponsiveFontSizes(24, 15),
  },
  headerWithBgImage: {
    marginTop: '3rem',
    backgroundImage: (props) =>
      props.headerBgImage &&
      props.headerBgImage.length > 0 &&
      `url(${props.headerBgImage[0].image}/m/2400x0)`,
    backgroundSize: 'cover',
    paddingBottom: '50px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    '& h1': {
      color: 'white !important',
    },
    '& p': {
      color: 'white !important',
    },
    '& h3': {
      color: 'white !important',
    },
  },
  containerClassWithBgImage: {
    maxWidth: '90%',
    margin: '0 auto',
    padding: '0px',
    marginTop: '-50px !important',
  },
  buttonLink: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    minWidth: '65px',
    boxShadow: 'none',
    fontWeight: theme.typography.fontWeightBold,
    padding: '4px 10px',
    fontSize: '0.7544642857142857rem',
    lineHeight: '1',
    minHeight: '26px',
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.611rem',
      '& span': {
        fontSize: '18px',
        marginLeft: '8px',
      },
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      minWidth: '65px',
      boxShadow: 'none',
      fontWeight: theme.typography.fontWeightBold,
      padding: '4px 10px',
      fontSize: '0.7544642857142857rem',
      lineHeight: '1',
      minHeight: '26px',
      border: '0px',
    },
  },
  ctaButtonSeeMore: {
    textAlign: 'center',
    margin: '4px 10px',
  },
  cardVisibility: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  equalSpacingCardImage: {
    padding: '8px 8px',
  },
  bigContent: (props) => ({
    padding: props.isNav ? '0' : '24px',
  }),
  titleAlignment: {
    textAlign: (props) => props.alignment,
  },
}))

const CardModule = (props) => {
  const {
    eyebrowLabel,
    title,
    description,
    ctaWithDivider,
    cta,
    references,
    cards,
    variants,
    clearBg,
    maxWidth,
    thumbnailHeight,
    isFullWidth,
    isNav,
    shouldRenderH1,
    removeMargin,
  } = props.blok

  const shouldRemoveContainer = !!props.removeContainer

  const updateHiddenFieldsValue = props.updateHiddenFieldsValue
  const checkForHeaderWithBgImageVariant = (variant) =>
    variant === 'header-with-bg-image'

  const classes = useStyles({
    ...props.blok,
    checkForHeaderWithBgImageVariant,
    isEventCardVisible: props.isEventCardVisible,
    shouldRemoveContainer: shouldRemoveContainer,
    removeMargin: removeMargin,
  })

  const isReferencingAllPublishedResources = get(
    references,
    '[0].isReferencingAllPublishedResources'
  )

  const resourceReferences = get(references, '[0].component')

  const updateParam = (value) => {
    const URLParams =
      references &&
      references[0].filters &&
      references[0].filters.filter(
        (filter) => filter.options.reduce((acc, value) => acc).fieldOptions
      )
    props.updateUrlParam(URLParams)
  }

  return (
    <SbEditable content={props.blok}>
      <PageContainer
        parentWidth={isFullWidth}
        margin={isNav ? '0px' : 'auto'}
        width={isNav ? '100%' : 'auto'}
        backgroundColor={clearBg ? 'clear' : 'default'}
      >
        <Box className={classes.background}>
          <ContentContainer
            parentWidth={variants === 'header-with-bg-image'}
            className={classes.bigContent}
          >
            <Box
              className={classNames(classes.textContainer, {
                [classes.headerWithBgImage]:
                  checkForHeaderWithBgImageVariant(variants),
              })}
            >
              {!!eyebrowLabel && (
                <H6 className={classes.eyebrow}>{eyebrowLabel}</H6>
              )}
              {!!title &&
                (variants === 'default' ||
                  variants === 'header-with-bg-image' ||
                  variants === 'lt-blue-title' ||
                  variants === 'bold-blue-title' ||
                  variants === 'black-background') && (
                  <H3
                    component={shouldRenderH1 ? 'h1' : 'h3'}
                    className={classNames(
                      classes.headline,
                      classes.titleAlignment,
                      {
                        [classes.lightBlueTitle]:
                          variants === 'lt-blue-title' ||
                          variants === 'bold-blue-title',
                        [classes.boldTitle]: variants === 'bold-blue-title',
                      }
                    )}
                  >
                    {title}
                  </H3>
                )}
              {!!title && variants === 'smaller-title' && (
                <H4 className={classes.headline}>{title}</H4>
              )}
              {!!title && variants === 'grey-title-w-line' && (
                <H5 className={classes.sectionTitle}>{title}</H5>
              )}
              {!!description && (
                <Box mt={2} mb={3} className={classes.descriptionContainer}>
                  <MarkdownHandler className={classes.description}>
                    {description}
                  </MarkdownHandler>
                </Box>
              )}
              {!!cta && cta.length > 0 ? (
                <Box className={classes.ctaButtonSeeMore}>
                  {renderBloks(cta, { updateHiddenFieldsValue })}
                </Box>
              ) : (
                !!references &&
                resourceReferences === 'resourceReferences' &&
                isReferencingAllPublishedResources && (
                  <Box className={classes.ctaButtonSeeMore}>
                    <Button
                      className={classes.buttonLink}
                      onClick={updateParam}
                    >
                      See More
                      <Icon>ChevronRight</Icon>
                    </Button>
                  </Box>
                )
              )}
            </Box>
            <Box
              container="true"
              className={classNames(classes.containerClass, {
                [classes.containerClassWithBgImage]:
                  checkForHeaderWithBgImageVariant(variants),
              })}
            >
              {!!cards &&
                cards.map((card) => (
                  <Box
                    key={card._uid}
                    className={classNames(classes.displayCards, {
                      [classes.cardVisibility]: card.notVisibleOnMobile,
                      [classes.equalSpacingCardImage]:
                        props.blok.equalSpacingCardImage,
                      [classes.displayCardsBlackBackground]:
                        variants === 'black-background',
                    })}
                  >
                    {renderBlok(card, {
                      cardModuleMaxWidth: maxWidth,
                      thumbnailHeight,
                      isNav,
                      variants,
                    })}
                  </Box>
                ))}
              {!!references && renderBloks(references)}
            </Box>
            {ctaWithDivider && (
              <Box className={classes.button}>
                {renderBloks(ctaWithDivider)}
              </Box>
            )}
          </ContentContainer>
        </Box>
      </PageContainer>
    </SbEditable>
  )
}

export default CardModule
